/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';

/**
 * NotificationComponent
 *
 * @export
 * @class NotificationComponent
 */
@Component({
  selector: 'dpa-notification',
  templateUrl: 'notification.component.html',
  styleUrls: ['notification.component.scss'],
})
export class NotificationComponent {}
