/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, GenericObject, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Endpoint, HttpService } from '@ws1c/intelligence-common';
import { IntelligenceServiceType } from '@ws1c/intelligence-models';
import {
  IntelNotification,
  NotificationActionType,
  NotificationSearchRequest,
  NotificationSearchResponse,
  NotificationServiceDetails,
  NotificationServiceDetailsUpdateRequest,
  NotificationViewType,
} from '@ws1c/notification/model';

/**
 * NotificationService
 * @export
 * @class NotificationService
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  /**
   * constructor
   * @param {HttpService} httpService
   * @memberof NotificationService
   */
  constructor(private httpService: HttpService) {}

  /**
   * getNotificationsServices
   * @returns {Observable<IntelligenceServiceType[]>}
   * @memberof NotificationService
   */
  public getNotificationsServices(): Observable<IntelligenceServiceType[]> {
    return this.httpService.get(Endpoint.NOTIFICATIONS_SERVICES).pipe(
      map((response: GenericObject) => {
        return response.data?.map((service: string) => IntelligenceServiceType[service]);
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getNotifications
   * @param {NotificationSearchRequest} searchRequest
   * @param {NotificationViewType} view
   * @returns {Observable<NotificationSearchResponse>}
   * @memberof NotificationService
   */
  public getNotifications(searchRequest: NotificationSearchRequest, view: NotificationViewType): Observable<NotificationSearchResponse> {
    return this.httpService.post(Endpoint.NOTIFICATIONS_VIEW(view), searchRequest).pipe(
      map((response: GenericObject) => deserialize(NotificationSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getNotification
   * @param {string} id
   * @returns {Observable<IntelNotification>}
   * @memberof NotificationService
   */
  public getNotification(id: string): Observable<IntelNotification> {
    return this.httpService.get(Endpoint.NOTIFICATIONS_INAPP_ID(id)).pipe(
      map((response: GenericObject) => deserialize(IntelNotification, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * updateNotifications
   * @param {string[]} notificationIds
   * @param {NotificationActionType} actionType
   * @returns {Observable<boolean>}
   * @memberof NotificationService
   */
  public updateNotifications(notificationIds: string[], actionType: NotificationActionType): Observable<boolean> {
    return this.httpService
      .post(Endpoint.NOTIFICATIONS_ACTION, {
        ids: notificationIds,
        action_type: actionType,
      })
      .pipe(
        map(() => true),
        catchError(requestErrorHandler),
      );
  }

  /**
   * getUnseenNotificationsCount
   * @returns {Observable<number>}
   * @memberof NotificationService
   */
  public getUnseenNotificationsCount(): Observable<number> {
    return this.httpService.get(Endpoint.NOTIFICATIONS_INAPP_COUNT).pipe(
      map((resposne: GenericObject) => resposne.data),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getNotificationSettings
   * @returns {Observable<NotificationServiceDetails[]>}
   * @memberof NotificationService
   */
  public getNotificationSettings(): Observable<NotificationServiceDetails[]> {
    return this.httpService.get(Endpoint.NOTIFICATIONS_SETTINGS).pipe(
      map((response: GenericObject) => {
        return response.data?.map((service: GenericObject) => deserialize(NotificationServiceDetails, service));
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * updateNotificationSettings
   * @param {NotificationServiceDetailsUpdateRequest} notificationServiceDetailsUpdateRequest
   * @returns {Observable<boolean>}
   * @memberof NotificationService
   */
  public updateNotificationSettings(notificationServiceDetailsUpdateRequest: NotificationServiceDetailsUpdateRequest): Observable<boolean> {
    return this.httpService.put(Endpoint.NOTIFICATIONS_SETTINGS, notificationServiceDetailsUpdateRequest).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }
}
