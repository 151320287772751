/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { NgModule } from '@angular/core';
import { DpaTranslateModule } from '@dpa/ui-common';

import { LocaleBundleId } from '@ws1c/intelligence-core/const';
import { loadBundle } from './i18n';

/**
 * NotificationTranslationModule
 *
 * @export
 * @class NotificationTranslationModule
 */
@NgModule({
  imports: [
    DpaTranslateModule.forChild({
      loadBundle,
      localeBundleId: LocaleBundleId.NOTIFICATION,
    }),
  ],
  exports: [DpaTranslateModule],
})
export class NotificationTranslationModule {}
