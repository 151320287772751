/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { NOTIFICATION_EFFECTS } from './effects';
import { NOTIFICATION_STORE_KEY } from './store/notification-store-key';
import { notificationReducerMap } from './store/notification.store';

/**
 * NotificationBaseModule
 *
 * @export
 * @class NotificationBaseModule
 */
@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(NOTIFICATION_STORE_KEY, notificationReducerMap),
    EffectsModule.forFeature(NOTIFICATION_EFFECTS),
  ],
})
export class NotificationBaseModule {}
