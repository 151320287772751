/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { NgModule } from '@angular/core';

import { NotificationBaseModule } from './notification-base.module';
import { NotificationRoutingModule } from './notification-routing.module';
import { NotificationTranslationModule } from './notification-translation.module';
import { NotificationComponent } from './notification.component';

/**
 * NotificationModule
 *
 * @export
 * @class NotificationModule
 */
@NgModule({
  declarations: [NotificationComponent],
  imports: [NotificationTranslationModule, NotificationBaseModule, NotificationRoutingModule],
})
export class NotificationModule {}
