/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FullPageElementService, WindowService } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { isEmpty } from 'lodash-es';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  startWith,
  switchMap,
  take,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs/operators';

import { UserService } from '@dpa-shared-merlot';
import { AppConfig, RouterExtensions } from '@ws1c/intelligence-common';
import { UserPreferenceCommonSelectors, UserPreferenceSelectors } from '@ws1c/intelligence-core';
import { IntelligenceServiceType, IntelOrg, Org, ROUTE_NAMES, UserAccount } from '@ws1c/intelligence-models';
import {
  IntelNotification,
  NotificationActionType,
  NotificationActionTypes,
  NotificationConstants,
  NotificationSearchRequest,
  NotificationSearchResponse,
  NotificationViewType,
} from '@ws1c/notification/model';
import { NotificationService } from '@ws1c/notification/services/notification.service';
import { NotificationCenterActions, NotificationCenterSelectors, NotificationState } from '@ws1c/notification/store';

/**
 * NotificationCenterEffects
 *
 * @export
 * @class NotificationCenterEffects
 */
@Injectable()
export class NotificationCenterEffects {
  /**
   * loadNotificationsServices$
   * @type {Observable<Action>}
   * @memberof NotificationCenterEffects
   */
  public loadNotificationsServices$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationCenterActions.loadNotificationsServices),
      startWith(NotificationCenterActions.loadNotificationsServices),
      switchMap(() => {
        return this.notificationService.getNotificationsServices().pipe(
          map((intelligenceServiceTypes: IntelligenceServiceType[]) =>
            NotificationCenterActions.loadNotificationsServicesSuccess({
              intelligenceServiceTypes,
            }),
          ),
          catchError(() => of(NotificationCenterActions.loadNotificationsServicesFailure())),
        );
      }),
    ),
  );

  /**
   * getBannerNotifications$
   * @type {Observable<Action>}
   * @memberof NotificationCenterEffects
   */
  public getBannerNotifications$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationCenterActions.getBannerNotifications),
      switchMap(() => {
        return this.notificationService.getNotifications(new NotificationSearchRequest(), NotificationViewType.BANNER).pipe(
          map((response: NotificationSearchResponse) =>
            NotificationCenterActions.getBannerNotificationsSuccess({
              bannerNotifications: response.results,
            }),
          ),
          catchError(() => of(NotificationCenterActions.getBannerNotificationsFailure())),
        );
      }),
    ),
  );

  /**
   * getCenterNotifications$
   * @type {Observable<Action>}
   * @memberof NotificationCenterEffects
   */
  public getCenterNotifications$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        NotificationCenterActions.getCenterNotifications,
        NotificationCenterActions.queryCenterNotifications,
        NotificationCenterActions.loadMoreNotifications,
      ),
      withLatestFrom(this.store.select(NotificationCenterSelectors.centerNotificationSearchRequest)),
      debounceTime(300),
      switchMap(([, searchRequest]: [ReturnType<typeof NotificationCenterActions.getCenterNotifications>, NotificationSearchRequest]) => {
        return this.notificationService.getNotifications(searchRequest, NotificationViewType.CENTER).pipe(
          map((centerNotificationsSearchResponse: NotificationSearchResponse) =>
            NotificationCenterActions.getCenterNotificationsSuccess({
              centerNotificationsSearchResponse,
            }),
          ),
          catchError(() => of(NotificationCenterActions.getCenterNotificationsFailure())),
        );
      }),
    ),
  );

  /*
   * getBellTrayNotifications$
   * @type {Observable<Action>}
   * @memberof NotificationCenterEffects
   */
  public getBellTrayNotifications$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        NotificationCenterActions.getBellTrayNotifications,
        NotificationCenterActions.queryBellTrayNotifications,
        NotificationCenterActions.refreshBellTrayNotifications,
      ),
      withLatestFrom(this.store.select(NotificationCenterSelectors.bellTrayNotificationSearchRequest)),
      switchMap(([, searchRequest]: [ReturnType<typeof NotificationCenterActions.getBellTrayNotifications>, NotificationSearchRequest]) => {
        return this.notificationService.getNotifications(searchRequest, NotificationViewType.BELL_TRAY).pipe(
          map((response: NotificationSearchResponse) =>
            NotificationCenterActions.getBellTrayNotificationsSuccess({
              bellTrayNotifications: response.results,
            }),
          ),
          catchError(() => of(NotificationCenterActions.getBellTrayNotificationsFailure())),
        );
      }),
    ),
  );

  /*
   * getNotificationDetails$
   * @type {Observable<Action>}
   * @memberof NotificationCenterEffects
   */
  public getNotificationDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationCenterActions.getNotificationDetails),
      switchMap(({ id }: ReturnType<typeof NotificationCenterActions.getNotificationDetails>) => {
        return this.notificationService.getNotification(id).pipe(
          map((notification: IntelNotification) =>
            NotificationCenterActions.getNotificationDetailsSuccess({
              notification,
            }),
          ),
          catchError(() => of(NotificationCenterActions.getNotificationDetailsFailure())),
        );
      }),
    ),
  );

  /**
   * dismissNotifications$
   * @type {Observable<Action>}
   * @memberof NotificationCenterEffects
   */
  public dismissNotifications$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationCenterActions.dismissNotifications),
      tap(() => {
        this.fullPageElementService.resizeContainer();
      }),
      switchMap(({ notificationIds, view }: ReturnType<typeof NotificationCenterActions.dismissNotifications>) => {
        return this.notificationService.updateNotifications(notificationIds, NotificationActionType.DISMISS).pipe(
          mergeMap(() => {
            const actions: Action[] =
              view === NotificationViewType.BANNER
                ? [NotificationCenterActions.getBannerNotifications()]
                : [NotificationCenterActions.refreshBellTrayNotifications(), NotificationCenterActions.showBellTrayDismissAlert()];
            return [...actions, NotificationCenterActions.dismissNotificationsSuccess()];
          }),
          catchError(() => [NotificationCenterActions.dismissNotificationsFailure()]),
        );
      }),
    ),
  );

  /**
   * toggleReadNotifications$
   * @type {Observable<Action>}
   * @memberof NotificationCenterEffects
   */
  public toggleReadNotifications$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationCenterActions.toggleReadNotifications),
      withLatestFrom(this.store.select(NotificationCenterSelectors.selectedNotificationIds)),
      switchMap(
        ([{ notificationIds, read }, selectedNotificationIds]: [
          ReturnType<typeof NotificationCenterActions.toggleReadNotifications>,
          Set<string>,
        ]) => {
          let ids: string[] = notificationIds;
          let resetSelectedNotifications = false;
          if (!ids?.length) {
            ids = Array.from(selectedNotificationIds);
            resetSelectedNotifications = true;
          }
          const actionType = read ? NotificationActionType.READ : NotificationActionType.UNREAD;
          return this.notificationService.updateNotifications(ids, actionType).pipe(
            mergeMap(() => [
              NotificationCenterActions.toggleReadNotificationsSuccess({
                notificationIds: ids,
                read,
                resetSelectedNotifications,
              }),
              NotificationCenterActions.refreshUnseenNotificationsCount(),
            ]),
            catchError(() => [NotificationCenterActions.toggleReadNotificationsFailure()]),
          );
        },
      ),
    ),
  );

  /**
   * getUnseenNotificationsCount$
   * @type {Observable<Action>}
   * @memberof NotificationCenterEffects
   */
  public getUnseenNotificationsCount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationCenterActions.getUnseenNotificationsCount),
      throttleTime(NotificationConstants.NOTIFICATION_POLLING_INTERVAL),
      switchMap(() => {
        return this.notificationService.getUnseenNotificationsCount().pipe(
          map((totalUnseenNotifications: number) =>
            NotificationCenterActions.getUnseenNotificationsCountSuccess({
              totalUnseenNotifications,
            }),
          ),
          catchError(() => of(NotificationCenterActions.getUnseenNotificationsCountFailure())),
        );
      }),
    ),
  );

  /**
   * refreshUnseenNotificationsCount$
   * @type {Observable<Action>}
   * @memberof NotificationCenterEffects
   */
  public refreshUnseenNotificationsCount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationCenterActions.refreshUnseenNotificationsCount),
      switchMap(() => {
        return this.notificationService.getUnseenNotificationsCount().pipe(
          map((totalUnseenNotifications: number) =>
            NotificationCenterActions.getUnseenNotificationsCountSuccess({
              totalUnseenNotifications,
            }),
          ),
          catchError(() => of(NotificationCenterActions.getUnseenNotificationsCountFailure())),
        );
      }),
    ),
  );

  /**
   * openNotificationActionPageOrUrl$
   * @type {Observable<Action>}
   * @memberof NotificationCenterEffects
   */
  public openNotificationActionPageOrUrl$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationCenterActions.openNotificationActionPageOrUrl),
        tap(({ notification }: ReturnType<typeof NotificationCenterActions.openNotificationActionPageOrUrl>) => {
          // if external link - open in new tab
          if (notification.actionUrl) {
            this.windowService.open(notification.actionUrl, '_blank');
            return;
          }
          // if internal route to external modules
          if (notification.actionPage.startsWith(NotificationActionTypes.END_USER_MANAGEMENT)) {
            this.routerExtensions.navigate(
              [NotificationConstants.ACTION_ROUTE_MAP[NotificationActionTypes.END_USER_MANAGEMENT]],
              NotificationConstants.ACTION_ROUTE_QUERY_PARAMS[NotificationActionTypes.END_USER_MANAGEMENT](notification.actionPage),
            );
            return;
          }
          // if internal route without params
          if (notification.actionPage && isEmpty(notification.actionPageParams)) {
            this.routerExtensions.navigate([NotificationConstants.ACTION_ROUTE_MAP[notification.actionPage]]);
            return;
          }
          // if internal route with params
          if (notification.actionPage && !isEmpty(notification.actionPageParams)) {
            this.routerExtensions.navigate([
              NotificationConstants.ACTION_ROUTE_WITH_PARAMS_MAP[notification.actionPage](notification.actionPageParams),
            ]);
          }
        }),
      ),
    { dispatch: false },
  );

  /**
   * handleRedirectFromEmail$
   * @type {Observable<Action>}
   * @memberof NotificationCenterEffects
   */
  public handleRedirectFromEmail$ = createEffect(
    () =>
      this.actions$.pipe(
        startWith(NotificationCenterActions.handleRedirectFromEmail),
        withLatestFrom(
          this.route.queryParams,
          this.store.select(UserPreferenceSelectors.getUserAccountInfo),
          this.store.select(UserPreferenceCommonSelectors.getOrgInfo),
        ),
        filter(
          ([_action, queryParams]: [ReturnType<typeof NotificationCenterActions.handleRedirectFromEmail>, Params, UserAccount, Org]) =>
            !!queryParams[AppConfig.QUERY_PARAM_ACTION_PAGE] || !!queryParams[AppConfig.QUERY_PARAM_ACTION_URL],
        ),
        take(1),
        tap(
          ([_action, queryParams, user, org]: [
            ReturnType<typeof NotificationCenterActions.handleRedirectFromEmail>,
            Params,
            UserAccount,
            Org,
          ]) => {
            // External link
            if (queryParams[AppConfig.QUERY_PARAM_ACTION_URL]) {
              this.windowService.open(queryParams[AppConfig.QUERY_PARAM_ACTION_URL]);
              return;
            }
            // if internal route to external modules
            const actionPage = queryParams[AppConfig.QUERY_PARAM_ACTION_PAGE];
            if (actionPage.startsWith(NotificationActionTypes.END_USER_MANAGEMENT)) {
              this.routerExtensions.navigate(
                [NotificationConstants.ACTION_ROUTE_MAP[NotificationActionTypes.END_USER_MANAGEMENT]],
                NotificationConstants.ACTION_ROUTE_QUERY_PARAMS[NotificationActionTypes.END_USER_MANAGEMENT](actionPage),
              );
              return;
            }
            const redirectRoute =
              NotificationConstants.ACTION_ROUTE_MAP[actionPage] ??
              NotificationConstants.ACTION_ROUTE_WITH_PARAMS_MAP[actionPage](queryParams);
            const redirectUrl = `${this.windowService.location.origin}/#/${redirectRoute}`;
            // Redirect to login page when there is not active session
            if (!user?.email) {
              this.routerExtensions.navigate([`/${ROUTE_NAMES.LOGIN}`], {
                queryParams: {
                  [AppConfig.QUERY_ORIGIN_PATH]: redirectUrl,
                },
              });
              return;
            }
            // Redirect to specific route if the logged org is same
            if (org.orgId === queryParams[AppConfig.QUERY_PARAM_ORG_HINT]) {
              this.routerExtensions.navigate([redirectRoute]);
              return;
            }
            // Force login to another org
            const newOrg = new IntelOrg({
              userName: user.email,
              id: queryParams[AppConfig.QUERY_PARAM_ORG_HINT],
            });
            this.userService.changeOrg(newOrg, redirectUrl);
          },
        ),
      ),
    { dispatch: false },
  );

  /**
   * Creates an instance of NotificationCenterEffects.
   * @param {Actions} actions$
   * @param {Store<NotificationState>} store
   * @param {NotificationService} notificationService
   * @param {FullPageElementService} fullPageElementService
   * @param {RouterExtensions} routerExtensions
   * @param {WindowService} windowService
   * @param {ActivatedRoute} route
   * @param {UserService} userService
   * @memberof NotificationCenterEffects
   */
  constructor(
    private actions$: Actions,
    private store: Store<NotificationState>,
    private notificationService: NotificationService,
    private fullPageElementService: FullPageElementService,
    private routerExtensions: RouterExtensions,
    private windowService: WindowService,
    private route: ActivatedRoute,
    private userService: UserService,
  ) {}
}
